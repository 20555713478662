import React from 'react';

import AdamHeadshot from 'assets/images/team/Modlin_Group_Adam_500.jpg';
import TraciHeadshot from 'assets/images/team/Modlin_Group_Traci_500.jpg';
import CarlHeadshot from 'assets/images/team/Modlin_Group_Carl_500.jpg';
import MaggieHeadshot from 'assets/images/team/Modlin_Group_Maggie_500.jpg';
import JasonHeadshot from 'assets/images/team/Modlin_Group_Jason_500.jpg';
import AndrewHeadshot from 'assets/images/team/Modlin_Group_Andrew_500.jpg';
import MarisaHeadshot from 'assets/images/team/Modlin_Group_Marisa_500.jpg';
import FrederiqueHeadshot from 'assets/images/team/Modlin_Group_Frederique_500.jpg';
import EduardoHeadshot from 'assets/images/team/Modlin_Group_Eduardo_500.jpg';

const executives = [
  {
    name: 'Adam D. Modlin',
    title: 'Founder and Chief Executive Officer',
    phone: '212.586.3821',
    phoneHref: '2125863821',
    email: 'adam@modlingroup.com',
    imgSrc: AdamHeadshot,
    description: [
      <p>
        Modlin Group is a bespoke boutique real estate agency in New York City. Founded by Adam D. Modlin in 1999, the
        company has a 20-year track record for closing high profile complex transactions in excess of $2 billion. The
        company has built its business solely on developing personal relationships with clients which span around the
        globe. Modlin Group has a reputation for its commitment to excellence, character, integrity and respect. The
        company has a niche for representing Townhouses, Penthouses and large residences. Modlin Group operates more
        like a family office than a traditional brokerage company. With a personal touch, Modlin Group plays the role as
        advisor and partner guiding clients with discretion, trust, privacy, professionalism and passion as its core
        values.
      </p>,
      <p>
        While Modlin Group’s expertise is in New York, the company has developed alliances and advises clients in the
        Hamptons, Boston, Miami, Los Angeles, Aspen, London, Tel Aviv and beyond.
      </p>,
      <p>
        Recently, Modlin Group represented the sale of the most expensive single-family townhouse in the history of New
        York, 14-16 East 67 th Street for $77 million.
      </p>,
    ],
  },
];

const agents = [
  {
    name: 'Traci L. Sands',
    title: 'Director of Administration & Operations',
    phone: '212.974.0740 [ext 13]',
    phoneHref: '2129740740,13',
    email: 'traci@modlingroup.com',
    imgSrc: TraciHeadshot,
    description: [
      <p>
        Traci L. Sands is a highly skilled administrator with 23 years experience in executive and staff administration
        as well as a strong background in marketing and sales management. She has valuable experience in office
        information technology, logistics, and training. Before joining The Modlin Group, Traci worked at notable top
        investment advisory and real estate firms specializing in multi-level client services. Traci has a proven
        herself to be extremely proficient in developing successful office policies and procedures which result in
        seamless and efficient operations with end goal being profitable ventures and acquisitions.
      </p>,
    ],
  },
  {
    name: 'Carl Gambino',
    title: 'Licensed Real Estate Salesperson',
    phone: '212.974.0740',
    phoneHref: '2129740740',
    cell: '646.465.1766',
    email: 'carl@modlingroup.com',
    imgSrc: CarlHeadshot,
    description: [
      <p>
        Carl Gambino is a leading real estate agent at renowned estate brokerage, Compass. Licensed in both New York
        City and Los Angeles , Gambino grossed over $150 million in residential real estate sales in the last
        twenty-four months. Previously a commercial agent at Marcus and Millichap in Manhattan, Gambino has broken
        several records since transitioning into residential sales. He works with a long list of notable names and
        high-profile clientele and is known for being extremely loyal, discreet and passionate about his work. He’s been
        quoted and featured in major media outlet’s including The Real Deal, Curbed, The Los Angeles Times, and Us
        Weekly.
      </p>,
    ],
  },
  {
    name: 'Maggie Gold Seelig',
    title: 'Licensed Associate Real Estate Broker',
    cell: '617.645.4999',
    email: 'maggie@maggiegoldseelig.com',
    imgSrc: MaggieHeadshot,
    description: [
      <p>
        A New York City native and lawyer by training, Maggie founded MGS Group Real Estate after working at Coldwell
        Banker. Widely recognized in the industry as a “top broker” for those seeking to buy or sell high-end
        properties, Maggie knows that being nimble and truly able to think ‘out of the box’ are critical to achieving
        her clients’ objectives. With her finger on the pulse of the market, Maggie is singularly focused on negotiating
        deal terms that exceed client expectations. Indeed, Maggie provides more than clear and sound real estate advice
        — she offers strategic thinking that enables her clients to realize every advantage.
      </p>,
      <p>
        Over her career, Maggie has advised and brokered some of largest, record breaking and most complicated real
        estate transactions in her core markets. She is often celebrated as a Top Real Estate Producer in the luxury
        residential real estate brokerage communities she serves and has been recognized for being a Top Real Estate
        Broker by the Wall Street Journal.
      </p>,
      <p>
        Maggie resides in Cambridge, MA and the Upper East Side of Manhattan. She has served on several philanthropic
        Boards and Committees over the past 20 years, including currently serving on the Board of Advisors of Harvard’s
        American Repertory Theatre and the Board of Philanthropic Overseers of Boston Children’s Hospital.
      </p>,
    ],
  },
  {
    name: 'Jason Katz',
    title: 'Licensed Real Estate Salesperson',
    phone: '212.974.0740 [ext 12]',
    phoneHref: '2129740740,12',
    cell: '646.660.4317',
    email: 'jason@modlingroup.com',
    imgSrc: JasonHeadshot,
    description: [
      <p>
        Jason Katz has been with Modlin Group since September 2014. Prior to joining Modlin Group, Jason worked at NBC
        News and NBC Sports for three years where he developed creative & technical skills in TV production and digital
        at the highly accomplished TODAY show and the Olympics.
      </p>,
      <p>
        Jason takes care of clients as if they are his family. He streamlines the process of buying, selling, and
        renting and breaks down the steps so that it's an enjoyable experience. Attention to detail, client service,
        transparency, and confidentiality are hallmarks of Jason’s ethic. Jason also has a knack for technology and
        connecting with people.
      </p>,
      <p>
        Jason moved to the Upper East Side from Memphis, TN. He graduated from The University of Maryland in 2014 where
        he majored in broadcast journalism. Jason’s interests include music, news, working out, and volunteering with
        New York Cares.
      </p>,
    ],
  },
  {
    name: 'Andrew Nierenberg',
    title: 'Licensed Real Estate Salesperson',
    phone: '212.974.0740 [ext 11]',
    phoneHref: '2129740740,11',
    email: 'andrew@modlingroup.com',
    imgSrc: AndrewHeadshot,
    description: [
      <p>
        Andrew Nierenberg has had an accomplished career in luxury sales, fashion, and hospitality for the last 12
        years. Andrew developed his detailed, driven and thorough hospitality approach first with five-star,
        five-diamond Four Seasons Hotel 57 th Street, as Maitre D’ at the late French Chef, highest ranked Chef in the
        world, Joel Robuchon’s first New York City restaurant. Following years spent in restaurants, Andrew’s
        student-like perspective, he then spent 8 years with Ralph Lauren – first in sales and most recently managing
        client experience and hospitality.
      </p>,
      <p>
        Andrew's avid approach for developing relationships, displaying integrity and privacy, has led to his
        incomparable performance of service -- that as a result, conditioned him to best service his Real Estate
        Clients. As a member of the boutique firm, Modlin Group, Andrew is committed to providing a world-class
        experience every Real Estate client deserves. Residing in New York with his wife and two children, Andrew is
        intimately familiar with the famed, New York City. Outside of real estate, he runs a foundation he and his
        brother created in memory of his late younger brother, Matthew -- The Matthew Nierenberg Heart of Gold
        Foundation.
      </p>,
    ],
  },
  {
    name: 'Marisa Sargent',
    title: 'Licensed Real Estate Salesperson',
    phone: '212.974.0740 [ext 16]',
    phoneHref: '2129740740,16',
    cell: '516.770.2359',
    email: 'marisa@modlingroup.com',
    imgSrc: MarisaHeadshot,
  },
  {
    name: 'Frederique Svider',
    title: 'Licensed Real Estate Salesperson',
    phone: '212.974.0740 [ext 18]',
    phoneHref: '2129740740,18',
    email: 'fred@modlingroup.com',
    imgSrc: FrederiqueHeadshot,
  },
  {
    name: 'Eduardo Cabrera',
    title: 'Licensed Real Estate Salesperson',
    phone: '212.974.0740 [ext 10]',
    phoneHref: '2129740740,10',
    cell: '941.447.6335',
    email: 'eduardo@modlingroup.com',
    imgSrc: EduardoHeadshot,
    description: [
      <p>
        Eduardo Cabrera is a licensed real estate broker in New York City. Bilingual with extensive international
        experience, he is passionate about real estate and ​strives to provide high-quality, personalized service to
        each and every one of his clients. Thanks to his committed work ethic and engaging personality, Eduardo builds
        trust and maintains strong relationships. As a valued member of the Modlin Group team, Eduardo represents the
        firm’s commitment to professionalism, discretion and above all, trust.
      </p>,
      <p>
        Originally from Bogotá, Colombia, Eduardo came to the United States to pursue tennis at the highest level.
        Eduardo was recruited by the world-renowned tennis division of the International Management Group (IMG) in
        Florida. Through rigorous training and professional competition, Eduardo earned a tennis scholarship at Dalton
        State University, where he received a BA in International Business. Balancing these demands shaped Eduardo into
        the disciplined and driven professional he is today.
      </p>,
    ],
  },
];

export { agents, executives };

export const localTeamData = [
  {
    id: 34,
    date: '2020-06-05T17:40:21',
    date_gmt: '2020-06-05T17:40:21',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=34',
    },
    modified: '2023-02-20T11:31:40',
    modified_gmt: '2023-02-20T11:31:40',
    slug: 'adam-modlin',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/adam-modlin/',
    title: {
      rendered: 'Adam Modlin',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Adam D. Modlin',
      position: 'Founder and Chief Executive Officer',
      phone: '2125863821',
      phone_extension: '',
      cell: '',
      email: 'adam@modlingroup.com',
      bio:
        '<p>Modlin Group is a boutique real estate agency in New York City. Founded by Adam D. Modlin in 1999, the company has a 20-year plus track record for closing high profile complex transactions. The company has built its business solely on developing personal relationships with clients which span around the globe. Modlin Group has a reputation for its commitment to excellence, character, integrity and respect. The company has a niche for representing Townhouses, Penthouses and large residences. Modlin Group operates more like a family office than a traditional brokerage company. With a personal touch, Modlin Group plays the role as advisor and partner guiding clients with discretion, trust, privacy, professionalism and passion as its core values.</p>\n<p>While Modlin Group’s expertise is in New York, the company has developed alliances and advises clients in the Hamptons, Boston, Miami, Los Angeles, Aspen, London, Tel Aviv and beyond.</p>\n<p>Recently, Modlin Group represented the sale of the most expensive single-family townhouse in the history of New York, 14-16 East 67 th Street for $77 million.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Adam_500.jpg',
      executive: true,
      location: 'MANHATTAN',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/34',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=34',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 53,
    date: '2020-06-05T18:23:40',
    date_gmt: '2020-06-05T18:23:40',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=53',
    },
    modified: '2020-07-02T16:24:48',
    modified_gmt: '2020-07-02T16:24:48',
    slug: 'eduardo-cabrera',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/eduardo-cabrera/',
    title: {
      rendered: 'Eduardo Cabrera',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Eduardo Cabrera',
      position: 'Licensed Real Estate Salesperson',
      phone: '2129740740',
      phone_extension: '10',
      cell: '9414476335',
      email: 'eduardo@modlingroup.com',
      bio:
        '<p>Eduardo Cabrera is a licensed real estate broker in New York City. Bilingual with extensive international experience, he is passionate about real estate and ​strives to provide high-quality, personalized service to each and every one of his clients. Thanks to his committed work ethic and engaging personality, Eduardo builds trust and maintains strong relationships. As a valued member of the Modlin Group team, Eduardo represents the firm’s commitment to professionalism, discretion and above all, trust.</p>\n<p>Originally from Bogotá, Colombia, Eduardo came to the United States to pursue tennis at the highest level. Eduardo was recruited by the world-renowned tennis division of the International Management Group (IMG) in Florida. Through rigorous training and professional competition, Eduardo earned a tennis scholarship at Dalton State University, where he received a BA in International Business. Balancing these demands shaped Eduardo into the disciplined and driven professional he is today.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Eduardo_500.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/53',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=53',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 127,
    date: '2023-02-09T19:12:19',
    date_gmt: '2023-02-09T19:12:19',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=127',
    },
    modified: '2023-02-09T19:20:12',
    modified_gmt: '2023-02-09T19:20:12',
    slug: 'stefanie-caputo',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/stefanie-caputo/',
    title: {
      rendered: 'Stefanie Caputo',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Stefanie Caputo',
      position: 'Director of Marketing | Licensed Real Estate Salesperson',
      phone: '',
      cell: '201.3960407',
      email: 'stefanie@modlingroup.com',
      bio:
        '<div class="page" title="Page 1">\n<div class="layoutArea">\n<div class="column">\n<p>Stefanie joins Modlin Group with more than ten years of marketing experience. Previously, she served as director of experiential marketing for Banyan Street Capital, a full-service real estate investment firm. In her role at Banyan Street, Stefanie oversaw branding, tenant relations and programming across the company and its portfolio of 13 million square feet. Stefanie brings a hospitality-driven approach and an expertise in luxury marketing to Modlin Group.</p>\n<p>Prior to entering the real estate industry, Stefanie oversaw business development for a luxury concierge firm in Manhattan, where she curated lifestyle programs and solutions for individual and corporate clients. The diversity of her professional experience and her ability to drive solutions equip her to deliver on Modlin Group’s commitment to excellence for its clients. She began her career at Republic Records, managing strategic marketing and brand partnerships for the label, after serving as executive assistant to the executive vice president. Stefanie graduated summa cum laude from the University of Miami with a Bachelor of Science in communications.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/Stefanie-Caputo-2.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/127',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=127',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 98,
    date: '2022-05-17T14:29:13',
    date_gmt: '2022-05-17T14:29:13',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=98',
    },
    modified: '2022-05-17T14:38:08',
    modified_gmt: '2022-05-17T14:38:08',
    slug: 'max-clayton',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/max-clayton/',
    title: {
      rendered: 'Max Clayton',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Max Clayton',
      position: 'Licensed Real Estate Salesperson | MGS Team',
      phone: '',
      cell: '6038604069',
      email: 'max@modlingroup.com',
      bio:
        '<p>Max comes to the Modlin Group as part of the MGS Group Real Estate Team and has taken great pleasure in assisting Buyers, Sellers, Landlords and Tenants achieve their real estate goals.</p>\n<p>A New Hampshire native, Max has called NYC home for the past 10 years, gaining insightful knowledge about living in this incredible City and can guide your real estate journey in every borough! Since his start in the business, Max has been known for being a great listener, fierce negotiator and kind person. He goes beyond brokerage to ensure that his clients’ needs are met and that their transactions are run smooth and with great care. In addition to his real estate career,  Max has been seen on the Great White Way in over 8 Broadway productions including Moulin Rouge! the Musical, Hello Dolly! with Bette Midler, and most recently The Music Man starring Hugh Jackman.  With his finger on the heart beat of NYC’s real estate market, Max is an incredible asset to all of his clients.</p>\n',
      headshot: '/images/cms/team/photo-from-richard-1.jpg',
      executive: false,
      phone_extension: '',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/98',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=98',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 146,
    date: '2023-02-10T17:59:54',
    date_gmt: '2023-02-10T17:59:54',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=146',
    },
    modified: '2023-02-27T22:14:08',
    modified_gmt: '2023-02-27T22:14:08',
    slug: 'chris-covert',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/chris-covert/',
    title: {
      rendered: 'Christopher Covert',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Christopher Covert',
      position: 'Managing Director | Licensed Associate Real Estate Broker',
      phone: '',
      cell: '9178340635',
      email: 'covert@modlingroup.com',
      bio:
        '<div class="page" title="Page 1">\n<div class="layoutArea">\n<div class="column">\n<p>In an ever-changing marketplace, the ability to correctly interpret data and convey trends with confidence to buyers and sellers alike is the cornerstone for successful relationships that lead to smooth transactions. Christopher has gained the trust of his clients through consistently precise data analysis, and he has developed a strong skill in identifying the right investment opportunities, from raw land for new construction to existing homes ripe for improvement. His comprehensive knowledge of town and village codes and regulations, coupled with his ongoing study of market movement at both micro and macro levels enables him to provide the most detailed evaluation.</p>\n<p>Covert also brings to his real estate endeavors a solid foundation in architecture, design and construction. A social media leader known for unique branding and marketing initiatives along with an aptitude for always changing technologies, he is able to make a distinctive impression on the Hamptons marketplace. His real estate career follows 20+ years in film, television and music, where his work as a creative executive shaped both his business acumen and his keen eye.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/Chris_Covert.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/146',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=146',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 128,
    date: '2023-02-09T19:12:41',
    date_gmt: '2023-02-09T19:12:41',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=128',
    },
    modified: '2023-02-09T19:20:37',
    modified_gmt: '2023-02-09T19:20:37',
    slug: 'peter-davis',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/peter-davis/',
    title: {
      rendered: 'Peter Davis',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Peter Davis',
      position: 'Licensed Real Estate Salesperson',
      phone: '',
      cell: '3473740062',
      email: 'peter@modlingroup.com',
      bio:
        '<div class="page" title="Page 1">\n<div class="layoutArea">\n<div class="column">\n<p>Peter Davis is a licensed real estate salesperson and REBNY member who has lived on the Upper East Side for over 27 years. Originally from Boston, he moved to NYC in 1995 to pursue his passion in the high end luxury market. A strong determination to succeed and keen business acumen led to a richly rewarding and highly successful 30-year career in luxury sales. Peter cherishes his long and deep relationships with some of the most important people in their industries. He joins the prestigious Modlin Group, bringing a commitment to offer the highest level of service, integrity and privacy the Modlin Group is internationally extolled for.</p>\n<p>Peter has a thoughtful and dedicated approach to his business, using a warm and genuine passion for building relationships first, recognizing that trust and integrity are vital towards building a solid foundation for the future. He has a strong set of core values that shapes his everyday life and contributes to his continued successes. Client needs are met with the utmost care and dedication, while offering solutions with an outside the box thought process and creative finesse.</p>\n<p>Peter has a passion for photography, art, fine dining and music. He enjoys hiking in the desert and mountains, and recently trained for and completed his first NYC Marathon 2022. He plans to complete the six world majors in the future.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/PeterDavis.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/128',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=128',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 160,
    date: '2023-02-27T20:36:58',
    date_gmt: '2023-02-27T20:36:58',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=160',
    },
    modified: '2023-02-27T20:36:58',
    modified_gmt: '2023-02-27T20:36:58',
    slug: 'berengere-gabet',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/berengere-gabet/',
    title: {
      rendered: 'Bérengère Gabet',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Bérengère Gabet',
      position: 'Licensed Real Estate Salesperson',
      phone: '3059518076',
      phone_extension: '',
      cell: '',
      email: 'bgabet@modlingroup.com',
      bio:
        '<p>A self-proclaimed citizen of the world, Berengere brings an international and eclectic background to the Hamptons real estate market. Born in France, she developed an early affection for the U.S. during travels with her family. After graduating from law school in Aix-en-Provence, she moved to Miami and continued her studies in motion pictures and photography. Subsequently, she was the wholesale manager of a luxury jewelry brand located on Place Vendome, Paris, where she also planned major events for an elite clientele and blue-chip companies. Later in New York City, she assisted in managing the development of several high-end lifestyle & hospitality projects.</p>\n<p>Since moving to the Hamptons in 2011, Berengere has built strong community relationships and brings 10 years of local real estate experience. Her idea of success is closely tied to providing unparalleled service to fulfill her clients’ goals.</p>\n',
      headshot: '/images/cms/team/Bea_Gabet-2.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/160',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=160',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 43,
    date: '2020-06-05T18:04:29',
    date_gmt: '2020-06-05T18:04:29',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=43',
    },
    modified: '2020-10-28T18:06:46',
    modified_gmt: '2020-10-28T18:06:46',
    slug: 'maggie-gold-seelig',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/maggie-gold-seelig/',
    title: {
      rendered: 'Maggie Gold Seelig',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Maggie Gold Seelig',
      position: 'Licensed Associate Real Estate Broker | Founder MGS Team',
      phone: '',
      phone_extension: '',
      cell: '6176454999',
      email: 'maggie@maggiegoldseelig.com',
      bio:
        '<p>A New York City native and lawyer by training, Maggie founded MGS Group Real Estate after working at Coldwell Banker. Widely recognized in the industry as a “top broker” for those seeking to buy or sell high-end properties, Maggie knows that being nimble and truly able to think ‘out of the box’ are critical to achieving her clients’ objectives. With her finger on the pulse of the market, Maggie is singularly focused on negotiating deal terms that exceed client expectations. Indeed, Maggie provides more than clear and sound real estate advice — she offers strategic thinking that enables her clients to realize every advantage.</p>\n<p>Over her career, Maggie has advised and brokered some of largest, record breaking and most complicated real estate transactions in her core markets. She is often celebrated as a Top Real Estate Producer in the luxury residential real estate brokerage communities she serves and has been recognized for being a Top Real Estate Broker by the Wall Street Journal.</p>\n<p>Maggie resides in Cambridge, MA and the Upper East Side of Manhattan. She has served on several philanthropic Boards and Committees over the past 20 years, including currently serving on the Board of Advisors of Harvard’s American Repertory Theatre and the Board of Philanthropic overseers of Boston Children’s Hospital.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Maggie_500.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/43',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=43',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 165,
    date: '2023-05-15T20:48:26',
    date_gmt: '2023-05-15T20:48:26',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=165',
    },
    modified: '2023-05-15T20:54:20',
    modified_gmt: '2023-05-15T20:54:20',
    slug: 'madison-hechler',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/madison-hechler/',
    title: {
      rendered: 'Madison Hechler',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Madison Hechler',
      position: 'Licensed Real Estate Salesperson',
      phone: '',
      cell: '7072662620',
      email: 'madison@modlingroup.com',
      bio:
        '<p>Madison grew up in Napa Valley, California, moving to New York to pursue real estate after graduating from the University of California, Santa Barbara, where she studied Political Science and Professional Writing, expanding her studies abroad in both London and Tel Aviv. She subsequently earned a postgraduate certificate in Architecture and Interior Design from the Pratt Institute.</p>\n<p>Clients remark that Madison’s around the clock availability, fast pace and attention to detail set her apart among real estate professionals. With an ear to the ground in the city’s culture, cuisine and social scene, she serves clients as a trusted guide for real estate and beyond, to all aspects of New York City life. Madison currently resides in downtown Manhattan.</p>\n',
      headshot: '/images/cms/team/K7A6010_Blk-e1684184054315.jpg',
      executive: false,
      location: 'MANHATTAN',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/165',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=165',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 45,
    date: '2020-06-05T18:06:48',
    date_gmt: '2020-06-05T18:06:48',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=45',
    },
    modified: '2020-06-15T19:10:23',
    modified_gmt: '2020-06-15T19:10:23',
    slug: 'jason-katz',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/jason-katz/',
    title: {
      rendered: 'Jason Katz',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Jason Katz',
      position: 'Licensed Real Estate Salesperson',
      phone: '2129740740',
      phone_extension: '12',
      cell: '6466604317',
      email: 'jason@modlingroup.com',
      bio:
        '<p>Jason Katz has been with Modlin Group since September 2014. Prior to joining Modlin Group, Jason worked at NBC News and NBC Sports for three years where he developed creative &amp; technical skills in TV production and digital at the highly accomplished TODAY show and the Olympics.</p>\n<p>Jason takes care of clients as if they are his family. He streamlines the process of buying, selling, and renting and breaks down the steps so that it&#8217;s an enjoyable experience. Attention to detail, client service, transparency, and confidentiality are hallmarks of Jason’s ethic. Jason also has a knack for technology and connecting with people.</p>\n<p>Jason moved to the Upper East Side from Memphis, TN. He graduated from The University of Maryland in 2014 where he majored in broadcast journalism. Jason’s interests include music, news, working out, and volunteering with New York Cares.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Jason_500.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/45',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=45',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 145,
    date: '2023-02-09T20:11:45',
    date_gmt: '2023-02-09T20:11:45',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=145',
    },
    modified: '2023-02-09T20:11:45',
    modified_gmt: '2023-02-09T20:11:45',
    slug: 'amy-landy',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/amy-landy/',
    title: {
      rendered: 'Amy Landy',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Amy Landy',
      position: 'Licensed Real Estate Salesperson',
      phone: '',
      cell: '9175977886',
      email: 'amy@modlingroup.com',
      bio:
        '<div class="page" title="Page 2">\n<div class="layoutArea">\n<div class="column">\n<p>Amy Landy is a true New Yorker. Born and raised in NYC, she has a natural passion for real estate and a firm pulse on the market. With a background in multifamily residential real estate, Amy has managed numerous Co-op and Condo sales as well as luxury rentals.</p>\n<p>She is a good listener, always prioritizes the needs of her clients, and makes sure to provide the highest level of service. Amy is an avid runner who loves exploring the city. She currently resides on the Upper East Side with her husband and four children.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/AmyLandy.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/145',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=145',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 47,
    date: '2020-06-05T18:18:27',
    date_gmt: '2020-06-05T18:18:27',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=47',
    },
    modified: '2020-06-15T19:07:40',
    modified_gmt: '2020-06-15T19:07:40',
    slug: 'andrew-nierenberg',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/andrew-nierenberg/',
    title: {
      rendered: 'Andrew Nierenberg',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Andrew Nierenberg',
      position: 'Licensed Real Estate Salesperson',
      phone: '2129740740',
      phone_extension: '11',
      cell: '9178363195',
      email: 'andrew@modlingroup.com',
      bio:
        '<p>Andrew Nierenberg has had an accomplished career in luxury sales, fashion, and hospitality for the last 12 years. Andrew developed his detailed, driven and thorough hospitality approach first with five-star, five-diamond Four Seasons Hotel 57 th Street, as Maitre D’ at the late French Chef, highest ranked Chef in the world, Joel Robuchon’s first New York City restaurant. Following years spent in restaurants, Andrew’s student-like perspective, he then spent 8 years with Ralph Lauren – first in sales and most recently managing client experience and hospitality.</p>\n<p>Andrew&#8217;s avid approach for developing relationships, displaying integrity and privacy, has led to his incomparable performance of service &#8212; that as a result, conditioned him to best service his Real Estate Clients. As a member of the boutique firm, Modlin Group, Andrew is committed to providing a world-class experience every Real Estate client deserves.</p>\n<p>Residing in New York with his wife and two children, Andrew is intimately familiar with the famed, New York City. Outside of real estate, he runs a foundation he and his brother created in memory of his late younger brother, Matthew &#8212; The Matthew Nierenberg Heart of Gold Foundation.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Andrew_500.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/47',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=47',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 149,
    date: '2023-02-10T18:00:04',
    date_gmt: '2023-02-10T18:00:04',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=149',
    },
    modified: '2023-02-21T15:22:02',
    modified_gmt: '2023-02-21T15:22:02',
    slug: 'michael-norbeck',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/michael-norbeck/',
    title: {
      rendered: 'Michael Norbeck',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Michael Norbeck',
      position: 'Licensed Real Estate Salesperson',
      phone: '',
      cell: '3476102065',
      email: 'michael@modlingroup.com',
      bio:
        '<div class="page" title="Page 2">\n<div class="layoutArea">\n<div class="column">\n<p>Mike joins Modlin Group Hamptons after nearly seven years at a local luxury Bridgehampton boutique real estate firm, prior to which he was involved in residential real estate in Manhattan for several years. Having grown up in East Quogue, Mike brings his experience back home to the East End, possessing a native&#8217;s knowledge of the villages, hamlets, and neighborhoods of the Hamptons.</p>\n<p>Applying that knowledge to his real estate endeavors, Mike not only welcomes clients to experience the best the Hamptons has to offer, but guides them to make astute investments as well.</p>\n<p>A true entrepreneur, Mike also owns one of the most successful businesses based at East Hampton Airport. It was his early interest in aviation that led to a career in that field, and he earned his pilot&#8217;s license while still in high school, following with a bachelor&#8217;s degree in Aeronautics. For 18 years, Mike worked at the East Hampton Airport for one of the fixed base operators, and continues to fly there recreationally.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/Michael_Norbeck.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/149',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=149',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 147,
    date: '2023-02-10T18:00:09',
    date_gmt: '2023-02-10T18:00:09',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=147',
    },
    modified: '2023-02-24T22:48:30',
    modified_gmt: '2023-02-24T22:48:30',
    slug: 'kate-ozturk',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/kate-ozturk/',
    title: {
      rendered: 'Kate Lopez',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: ' Kate Lopez',
      position: 'Director of Operations | Licensed as Ekaterina Ozturk',
      phone: '',
      cell: '6317452946',
      email: 'kate@modlingroup.com',
      bio:
        '<div class="page" title="Page 1">\n<div class="layoutArea">\n<div class="column">\n<p>Born and raised in Chelyabinsk, Russia, Kate had always dreamed of living abroad. Upon finishing her studies and having acquired a Master’s Degree in Linguistics and Intercultural Communication in 2010, she moved to Montauk. After initially establishing herself in the luxury hospitality industry, she soon transitioned to the luxury real estate business where she took expertise in management and customer service to the next level. Kate refers to herself as a people person, as anyone that has had the opportunity to work with her will confirm. Her knowledge of foreign languages and different cultures is a huge asset in the diverse community of the Hamptons. She is fluent in English and Russian, proficient in French, and just getting going with Spanish.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/Kate_Ozturk.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/147',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=147',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 148,
    date: '2023-02-10T18:00:15',
    date_gmt: '2023-02-10T18:00:15',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=148',
    },
    modified: '2023-02-24T22:50:59',
    modified_gmt: '2023-02-24T22:50:59',
    slug: 'alex-pashkkowsky',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/alex-pashkkowsky/',
    title: {
      rendered: 'Alex Pashkowsky',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Alex Pashkowsky',
      position: 'Licensed Real Estate Salesperson',
      phone: '',
      cell: '9179125746',
      email: 'alex@modlingroup.com',
      bio:
        '<div class="page" title="Page 2">\n<div class="layoutArea">\n<div class="column">\n<p>Alex joins Modlin Group Hamptons after nearly three years at an ultra-luxury boutique real estate firm in Water Mill that specializes in $10M and above properties. He came to real estate following a 25-year career in management consulting, where he helped guide executive leaders in strategy development, M&amp;A activities and business portfolio initiatives for such firms as Citibank, Merrill, Deutsche Bank, Visa, Pfizer, Facebook, Salesforce, Estee Lauder and leading private equity firms.</p>\n<p>Alex attributes his successful consulting career to paying detailed attention to his clients’ needs, simplifying complex situations and creating tangible results that build long term value for clients. He employs these same principles in real estate by listening closely to the homebuyer’s preferences and then presenting a curated selection of properties that fit the buyer’s requirements. For sellers, he creates customized marketing strategies to identify and attract the right buyers.</p>\n<p>Prior to consulting, Alex provided investment banking services to buy, sell and finance real estate projects for developers, property owners and institutional investors. He earned an MBA in Finance from NYU’s Stern School of Business and a BA in Finance from GWU.</p>\n<p>Alex has resided in Manhattan for 25 years and the Hamptons since 2010. Along with his partner, an acclaimed interior designer of ultra-luxury homes for high-end clients, he has owned homes in Water Mill, North Haven, and Sag Harbor, where he served as a member of the Architectural Review Board. He is passionate about architecture, design and maximizing the appreciation potential of real estate.</p>\n</div>\n</div>\n</div>\n',
      headshot: '/images/cms/team/Alex_Pashkkowsky.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/148',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=148',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 39,
    date: '2020-06-05T17:57:27',
    date_gmt: '2020-06-05T17:57:27',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=39',
    },
    modified: '2020-06-10T21:32:41',
    modified_gmt: '2020-06-10T21:32:41',
    slug: 'traci-l-sands',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/traci-l-sands/',
    title: {
      rendered: 'Traci L. Sands',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Traci L. Sands',
      position: 'Director of Administration & Operations',
      phone: '2129740740',
      phone_extension: '13',
      cell: '',
      email: 'traci@modlingroup.com',
      bio:
        '<p>Traci L. Sands is a highly skilled administrator with 23 years experience in executive and staff administration as well as a strong background in marketing and sales management. She has valuable experience in office information technology, logistics, and training. Before joining The Modlin Group, Traci worked at notable top investment advisory and real estate firms specializing in multi-level client services. Traci has a proven herself to be extremely proficient in developing successful office policies and procedures which result in seamless and efficient  operations with end goal being profitable ventures and acquisitions.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Traci_500.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/39',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=39',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 49,
    date: '2020-06-05T18:19:36',
    date_gmt: '2020-06-05T18:19:36',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=49',
    },
    modified: '2022-02-03T19:42:50',
    modified_gmt: '2022-02-03T19:42:50',
    slug: 'marisa-sargent',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/marisa-sargent/',
    title: {
      rendered: 'Marisa Sargent',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Marisa Sargent',
      position: 'Licensed Real Estate Salesperson',
      phone: '2129740740',
      phone_extension: '16',
      cell: '5167702359',
      email: 'marisa@modlingroup.com',
      bio:
        '<p>Marisa Sargent joined Modlin Group in 2007. Prior to joining Modlin Group, Marisa worked at Sotheby’s International Realty for three years, where she handled over $90 million in high-profile sales with a Senior Vice President. The success she’s achieved in her 18-year career is attributed to providing an outstanding level of service, attention to detail and a conscientious awareness of the needs of each client. Discretion and confidentiality are two key elements of Marisa&#8217;s business practices.</p>\n<p>Marisa, a native New Yorker, graduated with top honors from New York University&#8217;s Tisch School of the Arts. The urban setting of that campus gave her an early appreciation and knowledge of Manhattan real estate. That appreciation manifests itself in her sharp eye towards pricing and value of the properties she represents. Marisa&#8217;s interests include theater and sports. She is a former All- Long Island top ten ranked basketball player and Varsity captain. She brings to the real estate field, the same competitiveness and drive that were honed in that athletic arena. Marisa, a member of REBNY, builds true relationships with her clients. These relationships are built with her own knack of personalizing each transaction.</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Marisa_500.jpg',
      executive: false,
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/49',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=49',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 51,
    date: '2020-06-05T18:21:11',
    date_gmt: '2020-06-05T18:21:11',
    guid: {
      rendered: 'http://3.228.218.122/?post_type=modlin_group_team&#038;p=51',
    },
    modified: '2023-02-21T15:22:36',
    modified_gmt: '2023-02-21T15:22:36',
    slug: 'frederique-svider',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/frederique-svider/',
    title: {
      rendered: 'Frederique Svider',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Frederique Svider',
      position: 'Licensed Real Estate Salesperson',
      phone: '2129740740',
      phone_extension: '18',
      cell: '',
      email: 'fred@modlingroup.com',
      bio: '<p>N/A</p>\n',
      headshot: '/images/cms/team/Modlin_Group_Frederique_500.jpg',
      executive: false,
      location: 'MANHATTAN',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/51',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=51',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 183,
    date: '2023-05-15T20:48:26',
    date_gmt: '2023-05-15T20:48:26',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=183',
    },
    modified: '2023-05-15T20:54:20',
    modified_gmt: '2023-05-15T20:54:20',
    slug: 'flora-veitch',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/flora-veitch/',
    title: {
      rendered: 'Flora Veitch',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Flora Veitch',
      position: 'Licensed Real Estate Salesperson',
      phone: '8459013340',
      phone_extension: '',
      cell: '',
      email: 'flora@modlingroup.com',
      bio:
        '<p>Flora’s approach to real estate demonstrates a unique blend of creativity and strategy. Her primary commitment lies in educating her clients about the intricacies of the Hamptons market, thereby facilitating well-informed decision-making grounded in data. Flora is well-versed in both construction and renovation projects, as well as the landscape of village and town building codes and regulations. She attentively listens to her clients’ desires and requirements, approaching each deal with consideration and a measured demeanor in her actions and responses. Her assertive nature proves invaluable during negotiations, while her poised and comforting presence serves as a pillar of support for her clients.</p>\n<p>Flora embarked on her real estate journey with the aspiration of attaining independence from corporate structures and establishing her own business. Following a distinguished tenure in the financial sector during which she managed marketing for a global banking institution, she seamlessly transitioned to the realm of real estate. Her unwavering work ethic, combined with an unrelenting drive for success, has consistently enabled her to deliver tangible outcomes for her clients.</p>\n',
      headshot: '/images/cms/team/Flora-Veitch.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/183',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=183',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
  {
    id: 196,
    date: '2025-01-03T17:55:15',
    date_gmt: '2025-01-03T17:55:15',
    guid: {
      rendered: 'https://cms.modlingroup.dbox.com/?post_type=modlin_group_team&#038;p=196',
    },
    modified: '2025-01-03T17:55:15',
    modified_gmt: '2025-01-03T17:55:15',
    slug: 'dylan-huddleston',
    status: 'publish',
    type: 'modlin_group_team',
    link: 'https://cms.modlingroup.dbox.com/modlin_group_team/dylan-huddleston/',
    title: {
      rendered: 'Dylan Huddleston',
    },
    content: {
      rendered: '',
      protected: false,
    },
    featured_media: 0,
    template: '',
    acf: {
      name: 'Dylan Huddleston',
      position: 'Licensed Real Estate Salesperson',
      phone: '2018524478',
      phone_extension: '',
      cell: '',
      email: 'dylan@modlingroup.com',
      bio:
        '<p>With over a decade of experience in selling and renting homes in the Hamptons, Dylan brings a client-first approach to real estate. He takes the time to understand each client\u2019s unique goals, working collaboratively to create custom-tailored strategies that meet their needs. Specializing in all facets of listing management, he has developed an extensive knowledge of local building and zoning codes, enabling him to navigate even the most complex transactions seamlessly.</p>\n<p>Having been involved in over half a billion dollars in real estate transactions, Dylan attributes much of his success to his early experiences working for his family\u2019s glass and architectural metal business. There, he developed a strong work ethic and a solution-oriented mindset, which have shaped his ability to approach challenges creatively and efficiently.</p>\n<p>Passionate about building lasting relationships, Dylan firmly believes that his clients\u2019 success is the cornerstone of his own. He takes pride in working on unique and complex properties, finding challenging projects to be the most rewarding. Whether helping buyers find their dream home or guiding sellers to achieve their goals, Dylan remains dedicated to delivering exceptional service and results.</p>\n',
      headshot: '/images/cms/team/Dylan_Huddleston.jpg',
      executive: false,
      location: 'HAMPTONS',
    },
    _links: {
      self: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team/196',
        },
      ],
      collection: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/modlin_group_team',
        },
      ],
      about: [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/types/modlin_group_team',
        },
      ],
      'wp:attachment': [
        {
          href: 'https://cms.modlingroup.dbox.com/wp-json/wp/v2/media?parent=196',
        },
      ],
      curies: [
        {
          name: 'wp',
          href: 'https://api.w.org/{rel}',
          templated: true,
        },
      ],
    },
  },
];
