import React, { useState } from 'react'
import Context from '../config/Context'

import { localNewsData } from 'data/news'
import { localTeamData } from 'data/team'
import { localhomePageSectionsData } from 'data/homePageSections'

const parseWordpressRes = resArray => {
  const result = []
  resArray.forEach(el => {
    el.acf.id = el.id
    result.push(el.acf)
  })
  return result
}

const ContextProvider = props => {
  const [listings, setListings] = useState(null)
  const [listingsIslands, setListingsIslands] = useState(null)
  const [news, setNews] = useState(null)
  const [team, setTeam] = useState(null)
  const [homePageSections, sethomePageSections] = useState(null)
  const listingsHamptons = (require('../data/rets-listing.json') || []).sort(
    (a, b) => b.listing_details.current_price - a.listing_details.current_price
  )
  const listingsMontana = (require('../data/montana-listing.json') || []).sort(
    (a, b) => b.listing_details.current_price - a.listing_details.current_price
  )

  const listingsIslandsLocal = require('../data/islands-listing.json')

  return (
    <Context.Provider
      value={{
        ...props,
        listings,
        listingsIslands,
        news,
        team,
        homePageSections,
        listingsHamptons,
        listingsMontana,
        fetchListingsData: async () => {
          console.log('fetching availability data')
          const listingsRes = await fetch('https://residences.api.dbox.com/perchwell/modlin-group?projectname=modlin')
            .then(res => {
              return res.json()
            })
            .catch(error => {
              console.log('Error Fetching Availability Data - ', error)
              setListings('ERROR')
            })
          const listings = []
          const listingsIslands = listingsIslandsLocal || []
          listingsRes.forEach(listing => {
            if (listing.location.city === 'U.S. Virgin Islands') {
              listingsIslands.push(listing)
            } else {
              listings.push(listing)
            }
          })
          setListings(listings.sort((a, b) => b.listing_details.current_price - a.listing_details.current_price))
          setListingsIslands(
            listingsIslands.sort((a, b) => b.listing_details.current_price - a.listing_details.current_price)
          )
        },
        fetchNews: async ref => {
          console.log('fetching news data')
          await fetch('https://cms.modlingroup.dbox.com/index.php/wp-json/wp/v2/modlin_group_news?per_page=100')
            .then(response => response.json())
            .then(data => {
              setNews(parseWordpressRes(data))
            })
            .catch(error => {
              console.log('Error Fetching Press Data - ', error)
              console.log('Using local news data')
              setNews(parseWordpressRes(localNewsData))
            })
          setTimeout(() => {
            ref.current.slickNext()
          }, 500)
        },
        fetchTeam: async () => {
          console.log('fetching team data')
          await fetch('https://cms.modlingroup.dbox.com/index.php/wp-json/wp/v2/modlin_group_team?per_page=100')
            .then(response => response.json())
            .then(data => {
              setTeam(parseWordpressRes(data))
            })
            .catch(error => {
              console.log('Error Fetching Team Data - ', error)
              console.log('Using local news data')
              setTeam(parseWordpressRes(localTeamData))
            })
        },
        fetchHomePageSections: async () => {
          console.log('fetching home page sections data')
          await fetch('https://cms.modlingroup.dbox.com/index.php/wp-json/wp/v2/home_page_sections?per_page=100')
            .then(response => response.json())
            .then(data => {
              sethomePageSections(parseWordpressRes(data))
            })
            .catch(error => {
              console.log('Error Fetching Team Data - ', error)
              console.log('Using local news data')
              sethomePageSections(parseWordpressRes(localhomePageSectionsData))
            })
        },
        getCurrentListings: path => {
          const [currentRoute] = path.match(/\/[\w-]+/) || []
          switch (currentRoute) {
            case '/listings-hamptons':
              return listingsHamptons
            case '/listings-montana':
              return listingsMontana
            case '/listings-caribbean':
              return listingsIslands
            case '/listings':
            default:
              return listings
          }
        },
        getCurrentListingsBaseRoute: path => {
          const [currentRoute] = path.match(/\/[\w-]+/) || []
          return currentRoute
        }
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export default ContextProvider
