import React from 'react'
import styled from 'styled-components'

const HeroImageContainer = styled.div`
  position: relative;
  width: 100%;
  img {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
`

const GalleryButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 8px 20px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  border: 1px solid #fff;
  font-size: 14px;
  line-height: 18px;
  margin-left: 20px;
`

const HeroImage = ({ currentListing, toggleImageGallery, toggleVideoModal, toggleFloorModal }) => {
  const {
    media: { original_image_list, original_photo_list }
  } = currentListing

  return (
    <HeroImageContainer>
      <ButtonContainer>
        <GalleryButton
          onClick={e => {
            e.preventDefault()
            toggleImageGallery()
          }}
        >
          IMAGE GALLERY
        </GalleryButton>

        {(currentListing.media.main_floor_plan || (currentListing.media.floor_plan_list && currentListing.media.floor_plan_list.length)) && (
          <GalleryButton onClick={() => toggleFloorModal()}>VIEW FLOORPLAN</GalleryButton>
        )}

        {(currentListing.media.virtual_tour_url || currentListing.media.video_url) && (
          <GalleryButton onClick={() => toggleVideoModal()}>VIEW VIDEO</GalleryButton>
        )}

      </ButtonContainer>

      <img src={(original_image_list || original_photo_list)[0]} alt='test' />
    </HeroImageContainer>
  )
}

export default HeroImage
